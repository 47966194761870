import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchClasses(ctx, params) {
      return useJwt.getClasses(params).then(response => response);
    },
    exportClasss(ctx, params) {
      return useJwt.exportClasss(params).then(response => response);
    },
    generateImportClassTemplate(ctx, params) {
      return useJwt.generateImportClassTemplate(params).then(response => response);
    },
    createClass(ctx, data) {
      return useJwt.createClass(data).then(response => response);
    },
    updateClass(ctx, data) {
      return useJwt.updateClass(data).then(response => response);
    },
    importClasss(ctx, data) {
      return useJwt.importClasss(data).then(response => response);
    },
    getClassDetail(ctx, id) {
      return useJwt.getClassDetail(id).then(response => response);
    },
    deleteClasses(ctx, data) {
      return useJwt.deleteClasses(data).then(response => response);
    },
  },
};
