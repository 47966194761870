<template>
  <b-modal
    id="modal-class"
    ref="refModal"
    title="Lớp học"
    size="lg"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >

        <b-row>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="school"
              rules="required"
            >
              <select-school
                v-model="itemLocal.school"
                required
                :default-value="itemLocal.school"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="name"
              rules="required"
            >
              <b-form-group
                label="Tên lớp"
              >
                <template
                  v-slot:label
                >
                  Tên lớp
                  <span
                    class="text-danger"
                  >
                    (*)
                  </span>
                </template>
                <b-form-input
                  id="name"
                  v-model="itemLocal.name"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="teacherName"
              rules=""
            >
              <b-form-group
                label="Giáo viên chủ nhiệm"
              >
                <template
                  v-slot:label
                >
                  Giáo viên chủ nhiệm
                </template>
                <b-form-input
                  id="teacherName"
                  v-model="itemLocal.teacherName"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="teacherPhone"
              rules=""
            >
              <b-form-group
                label="SĐT giáo viên"
              >
                <template
                  v-slot:label
                >
                  SĐT giáo viên
                </template>
                <b-form-input
                  id="teacherPhone"
                  v-model="itemLocal.teacherPhone"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="teacherEmail"
              rules=""
            >
              <b-form-group
                label="Email giáo viên"
              >
                <template
                  v-slot:label
                >
                  Email giáo viên
                </template>
                <b-form-input
                  id="teacherEmail"
                  v-model="itemLocal.teacherEmail"
                  placeholder=""
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="tingee"
              rules=""
            >
              <select-tingee-account
                v-model="itemLocal.tingee"
                label="Hình thức thanh toán"
                required
                :default-value="itemLocal.tingee"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
                :ignore-cash="true"
              />
            </validation-provider>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import useClassModal from './useClassModal';
import SelectSchool from '../../components/SelectSchool.vue';
import SelectTingeeAccount from '../../components/SelectTingeeAccount.vue';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,

    SelectSchool,
    SelectTingeeAccount,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,

      onSubmit,
    } = useClassModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
